/*Change record modal text fields*/
.cr-textarea {
    overflow-y: scroll;
    height: 100px;
}
/*Edit/view button*/
.edit-btn::after {
    content: "\2197";
}

/*Home page search and filter fields*/
.home-container
{
    padding: 15px
}



.searchForm
{
    width: 740px;
}

.navigate-error-alert {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 20px;
    z-index: 9999;
}

.divider {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 20px 0;
}

    .divider:before,
    .divider:after {
        content: '';
        flex-grow: 1;
        height: 1px;
        background-color: #ccc;
    }

.divider-text {
    padding: 0 30px;
    color: #888;
}

.filter-from-view{
    padding-left: 40px;
}
.add-change-btn-col
{
    display: inline-flex;
}
.add-change-btn{
    margin-left: auto;
    margin-right: 0;
    margin-top: 16px; 
    /* background-color: green;
    border-color: green; */
}
.add-change-btn:hover{
    background-color: #006400;
    border-color: #006400;
}
.header-row
{
    padding: 8px;
}
.datepicker-title
{
    width: auto;
    padding-right: 0px;
    align-content: center;
    padding-bottom: 5px;
}

.title-cell
{
    max-width: 300px;
    white-space: normal;
    word-wrap: break-word;
}

.change-id-cell
{
    max-width: 100px;
    word-wrap: break-word;
}

.end-time-cell,.start-time-cell
{
    max-width: 90px;
    word-wrap: break-word;
}

.diff-dropdown
{
    padding-right: 20px;
}

input[type=number].no-arrow::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
-webkit-appearance: none; 
 margin: 0; 
}

.description-text {
    font-size: 0.9rem;
    color: #666;
    font-weight: 400;
    margin-top: 0;
}

.Input-Bar-Item {
    display: table-cell;
    padding-top: 10px;
    font-weight: 700;
    display: inline-block;
    margin-bottom: 20px;
    margin-top: 10px;
}
  
.ViewButtonGroup {
    position: absolute;
    top: 0;
    right: 0;
}

.SetViewButton {
    margin: 0 auto;
    background-color: #1C39BB;
}

.ZoomButtonGroup {
    position: absolute;
    top: 0;
    left: 0;
}

.ZoomButton {
    margin: 0 auto;
    background-color: #1C39BB;
    padding-left: 15px;
    padding-right: 15px;
}

.CopyConflictDevicesButtonGroup {
    position: absolute;
    bottom: 0;
    left: 0;
}

.CopyConflictDevicesButton {
    margin: 0 auto;
    background-color: #ff4d4d;
}

.CopyConflictDevicesButton:hover {
    background-color: #ff7474;
}

.CopyConflictDevicesButton:focus {     
    background-color: #ff4d4d;
}

.container {
  width: 100%;
  height: 90vh;
  position: relative;
  border: 2px solid black;
  border-radius: 5px;
}

.container::before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.3; 
  background-image: url('../assets/grid.png');
  background-size: 15px;
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
}

.normalNode {
  fill: rgb(22, 130, 218);
}

.conflictingNode {
  fill: rgb(246, 0, 0);
}

.node text {
  font: 12px sans-serif;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 200px;
  padding: 10px;
  font: 12px sans-serif;
  background: lightgreen;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
  font-weight: 700;
}
.App {
    text-align: center;
    margin-bottom: 10px;
}

.App-header {
    background-color: #1C39BB;
    min-height: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 2vmin);
    font-weight: 500;
    color: white;
}

.Logo {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0.5%;
    left: 1%;
}

.AppText {
    font-weight: 450;
}

.ErrorMessage {
    font-weight: 700;
    color: #ff4d4d;
}

.InputButton {
    margin: 0 auto;
    background-color: #1C39BB;
}

.input-group-btn.input-space {
    padding-left: 15px;
}

.app-tile {
    width: 100px;
    height: 100px;
    background: #3072ab;
    background: hsla(207.8,56.2%,42.9%,.7);
    margin-right: 10px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    line-height: 100px;
    margin-bottom: 10px;
    display: block;
}

.auth-card {
    width: 25rem;
    margin: auto;
    padding: 25px;
}

.sign-in-btn{
    padding-top: 15px;
}

.table-header {
    vertical-align: middle;
}

